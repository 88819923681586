import './Groups.css';
import React, { useEffect, useState } from 'react';
import apiCall from '../../../services/api';
import { Redirect, Link } from 'react-router-dom';
import { MdAdd as AddIcon } from 'react-icons/md';
import ExploreList from '../../../components/ExploreList';

function Groups() {
    const [status, setStatus] = useState(null);
    const [groups, setGroups] = useState([]);

    let groupsListData = groups.map(group => {
        return { label: group.label, link: '/admin/groups/edit?id=' + group.id };
    });

    useEffect(() => {
        apiCall({
            url: '/api/admin/groups',
            method: 'GET',
        }).then(
            result => {
                if (result.success) {
                    setGroups(result.data);
                    setStatus(true);
                } else setStatus(false);
            },
            error => {
                setStatus(false);
                console.error(error);
            }
        );
    }, []);

    if (status === null) {
        return <>Chargement...</>;
    } else if (status === false) {
        return <Redirect to="/"></Redirect>;
    } else
        return (
            <>
                <Link className="add-button" to="/admin/groups/edit">
                    <button>
                        <AddIcon /> Add group
                    </button>
                </Link>
                <ExploreList items={groupsListData} />
            </>
        );
}

export default Groups;
