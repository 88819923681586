import './NotFound.css';

function NotFound() {
    return (
        <div className="page">
            <h1>This page doesn't exist.</h1>
            <h2>HTTP Error 404</h2>
        </div>
    );
}

export default NotFound;
