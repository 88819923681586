import React, { useEffect, useState } from 'react';
import apiCall from '../../../services/api';
import { Redirect } from 'react-router-dom';
import { EditItem, EditItemFormProps } from '../../../components/EditItem';
import { Group, App } from '../../../../../types/types';
import EditList from '../../../components/EditList';

function EditUser() {
    return <EditItem itemType="users" itemForm={EditUserForm} />;
}

function EditUserForm(props: EditItemFormProps) {
    let userData = props.itemData;
    const setUserData = props.onItemUpdate;

    if (!userData.username) {
        userData = {
            username: '',
            password: '',
            isadmin: false,
        };
    }

    const userGroupIds: number[] = userData.groups || [];
    const userAppIds: number[] = userData.apps || [];

    const [status, setStatus] = useState(null);
    const [appData, setAppData]: [{ groups: Group[]; apps: App[] }, Function] = useState({
        groups: [],
        apps: [],
    });

    useEffect(() => {
        function onError(error) {
            setStatus(false);
            console.error(error);
        }

        let datas = { groups: [], apps: [] };
        apiCall({
            url: '/api/admin/groups',
            method: 'GET',
        })
            .then(result => {
                if (result.success) {
                    datas.groups = result.data;
                    return apiCall({
                        url: '/api/admin/apps',
                        method: 'GET',
                    });
                } else throw new Error("Couldn't fetch groups");
            }, onError)
            .then(result => {
                if (result.success) {
                    datas.apps = result.data;
                    setAppData(datas);
                }
                setStatus(result.success);
            }, onError);
    }, []);

    function changeUsername(event) {
        setUserData({ ...userData, username: event.target.value });
    }

    function changePassword(event) {
        setUserData({ ...userData, password: event.target.value });
    }

    function setUserGroups(groups) {
        setUserData({ ...userData, groups });
    }

    function setUserApps(apps) {
        setUserData({ ...userData, apps });
    }

    function setIsAdmin(event) {
        setUserData({ ...userData, isadmin: event.target.checked });
    }

    if (status === null) {
        return 'Chargement...';
    } else if (status === false) {
        return <Redirect to="/admin/users"></Redirect>;
    } else {
        return (
            <>
                <div className="input-label">Username</div>
                <input
                    type="text"
                    name="username"
                    value={userData.username}
                    onChange={changeUsername}
                />
                <div className="input-label">Password</div>
                <input
                    type="password"
                    name="password"
                    value={userData.password}
                    onChange={changePassword}
                />
                <div className="input-label">Is admin ?</div>
                <input
                    type="checkbox"
                    id="isadmin"
                    name="isadmin"
                    checked={userData.isadmin}
                    onChange={setIsAdmin}
                ></input>
                <h2>Edit Groups</h2>
                <EditList
                    selected={userGroupIds}
                    onChangeSelected={setUserGroups}
                    items={appData.groups.map(group => {
                        return { id: group.id, label: group.label };
                    })}
                />
                <h2>Edit Allowed Apps</h2>
                <EditList
                    selected={userAppIds}
                    onChangeSelected={setUserApps}
                    items={appData.apps.map(app => {
                        return { id: app.id, label: app.title };
                    })}
                />
            </>
        );
    }
}

export default EditUser;
