import React, { useEffect, useState } from 'react';
import apiCall from '../../../services/api';
import { Redirect } from 'react-router-dom';

import { EditItem, EditItemFormProps } from '../../../components/EditItem';
import { App } from '../../../../../types/types';
import EditList from '../../../components/EditList';

function EditGroup() {
    return <EditItem itemType="groups" itemForm={EditGroupForm} />;
}

function EditGroupForm(props: EditItemFormProps) {
    const groupData = props.itemData;
    const setGroupData = props.onItemUpdate;
    const appIds = groupData.apps || [];

    const [status, setStatus] = useState(null);

    const [allApps, setAllApps]: [App[], Function] = useState([]);

    useEffect(() => {
        apiCall({
            url: '/api/admin/apps',
            method: 'GET',
        }).then(
            result => {
                if (result.success) {
                    setAllApps(result.data);
                }
                setStatus(result.success);
            },
            error => {
                setStatus(false);
                console.error(error);
            }
        );
    }, []);

    function changeGroupLabel(event) {
        setGroupData({ ...groupData, label: event.target.value });
    }

    function setApps(apps) {
        setGroupData({ ...groupData, apps });
    }

    if (status === null) {
        return 'Chargement...';
    } else if (status === false) {
        return <Redirect to="/admin/users"></Redirect>;
    } else {
        return (
            <>
                <div className="input-label">Name</div>
                <input
                    type="text"
                    name="label"
                    value={groupData.label}
                    onChange={changeGroupLabel}
                />
                <h2>Allowed apps</h2>
                <EditList
                    selected={appIds}
                    onChangeSelected={setApps}
                    items={allApps.map(app => {
                        return { id: app.id, label: app.title };
                    })}
                />
            </>
        );
    }
}

export default EditGroup;
