import './Users.css';
import React, { useEffect, useState } from 'react';
import apiCall from '../../../services/api';
import { Redirect, Link } from 'react-router-dom';
import { MdPersonAdd as AddUserIcon } from 'react-icons/md';
import ExploreList from '../../../components/ExploreList';

function Users() {
    const [status, setStatus] = useState(null);
    const [users, setUsers] = useState([]);

    let usersListData = users.map(user => {
        return { label: user.username, link: '/admin/users/edit?id=' + user.id };
    });

    useEffect(() => {
        apiCall({
            url: '/api/admin/users',
            method: 'GET',
        }).then(
            result => {
                if (result.success) {
                    setUsers(result.data);
                    setStatus(true);
                } else setStatus(false);
            },
            error => {
                setStatus(false);
                console.error(error);
            }
        );
    }, []);

    if (status === null) {
        return <>Chargement...</>;
    } else if (status === false) {
        return <Redirect to="/"></Redirect>;
    } else
        return (
            <>
                <Link className="add-button" to="/admin/users/edit">
                    <button>
                        <AddUserIcon /> Add new user
                    </button>
                </Link>
                <ExploreList items={usersListData} />
            </>
        );
}

export default Users;
