import './EditList.css';
import React, { useState } from 'react';
import { MdClear as DeleteGroupIcon, MdAdd as AddIcon } from 'react-icons/md';

import Select from '../components/Select';

interface EditListProps {
    selected: number[];
    items: { id: number; label: string }[];
    onChangeSelected: Function;
}

export default function EditList(props: EditListProps) {
    const selectedItems = props.selected;

    const [selectedItemId, setSelectedItem]: [number, Function] = useState(null);

    let notSelectedItems = props.items;
    if (selectedItems.length !== 0) {
        notSelectedItems = notSelectedItems.filter(item => !selectedItems.includes(item.id));
    }

    let selectedItemIdToAdd = selectedItemId;
    if (
        notSelectedItems.length > 0 &&
        notSelectedItems.find(i => i.id === selectedItemId) == null
    ) {
        selectedItemIdToAdd = notSelectedItems[0].id;
    }

    function deleteItem(itemId: number) {
        props.onChangeSelected(selectedItems.filter(g => g !== itemId));
    }

    function addItem() {
        props.onChangeSelected([...selectedItems, selectedItemIdToAdd]);
    }

    function changeSelectedValue(event) {
        setSelectedItem(parseInt(event.target.value));
    }

    const options = notSelectedItems.map(i => {
        return { key: i.id, value: i.label };
    });

    function renderSelectedItems() {
        return (
            <div className="selected-items">
                {props.items
                    .filter(g => !notSelectedItems.includes(g))
                    .map(item => (
                        <span key={item.id} className="item">
                            <span className="item-contents">
                                <span className="item-name">{item.label}</span>
                                <button className="delete-item">
                                    <DeleteGroupIcon
                                        onClick={() => deleteItem(item.id)}
                                        className="item-delete-icon"
                                    />
                                </button>
                            </span>
                        </span>
                    ))}
            </div>
        );
    }

    return (
        <div className="edit-list">
            {renderSelectedItems()}
            {options.length !== 0 && (
                <div className="flex-grid-300">
                    <Select
                        value={selectedItemIdToAdd || 0}
                        onChange={changeSelectedValue}
                        options={options}
                    />
                    <button disabled={notSelectedItems.length === 0} onClick={addItem}>
                        <AddIcon /> Add
                    </button>
                </div>
            )}
        </div>
    );
}
