import React from 'react';
import { Link } from 'react-router-dom';
import './ExploreList.css';

function ExploreList(props) {
    const items = props.items;
    function renderItems() {
        if (items.length === 0) {
            return;
        }

        return items.map(item => {
            return (
                <Link key={item.label} className="user" to={item.link}>
                    {item.label}
                </Link>
            );
        });
    }
    return <div className="explore-list">{renderItems()}</div>;
}

export default ExploreList;
