import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import './Login.css';
import apiCall from '../../services/api';

function Login(props) {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [loginError, setLoginError] = useState('');

    function updateUsername(event) {
        setUsername(event.target.value);
    }

    function updatePassword(event) {
        setPassword(event.target.value);
    }

    function doPasswordLogin() {
        apiCall({
            url: '/api/login',
            method: 'POST',
            body: {
                username,
                password,
            },
        }).then(
            result => {
                if (result.success) {
                    setIsLoggedIn(true);
                    props.setUserData(result.data);
                } else {
                    setLoginError('Login/Password Error');
                }
            },
            error => {
                console.error(error);
            }
        );
    }

    if (isLoggedIn) {
        return <Redirect to="/" />;
    }

    return (
        <div className="login-page">
            <label htmlFor="username">Username</label>
            <input value={username} onChange={updateUsername} type="text" name="username" />
            <label htmlFor="password">Password</label>
            <input value={password} onChange={updatePassword} type="password" name="password" />
            <div className="error-message">{loginError}</div>
            <button type="button" onClick={doPasswordLogin}>
                Log-in
            </button>
        </div>
    );
}

export default Login;
