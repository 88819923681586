import './EditItem.css';
import React, { useEffect, useState } from 'react';
import { MdSave as SaveIcon, MdDelete as DeleteIcon } from 'react-icons/md';
import { Redirect } from 'react-router-dom';
import apiCall from '../services/api';

import { useLocation } from 'react-router-dom';
import { entityTypes } from '../../../types/types';

export interface EditItemFormProps {
    itemData: any;
    onItemUpdate: Function;
}

interface EditItemProps {
    itemForm: any;
    itemType: entityTypes;
}

export function EditItem(props: EditItemProps) {
    const ItemForm = props.itemForm;
    const itemType = props.itemType;
    const search = useLocation().search;
    const [status, setStatus] = useState(null);
    const [item, setItem]: [any, Function] = useState({});
    const itemId = new URLSearchParams(search).get('id');
    const editMode = itemId && itemId !== '';

    useEffect(() => {
        if (itemId && itemId !== '') {
            apiCall({
                url: `/api/admin/${itemType}/${itemId}`,
                method: 'GET',
            }).then(
                result => {
                    if (result.success) {
                        setItem(result.data);
                    }

                    setStatus(result.success);
                },
                error => {
                    setStatus(false);
                    console.error(error);
                }
            );
        } else setStatus(true);
    }, [itemId, itemType]);

    function onDelete() {
        apiCall({
            url: `/api/admin/${itemType}/${itemId}`,
            method: 'DELETE',
        }).then(() => {
            setStatus(false);
        });
    }

    function onSave() {
        if (editMode) {
            const data = { ...item };
            delete data.id;
            apiCall({
                url: `/api/admin/${itemType}/${itemId}`,
                method: 'PUT',
                body: data,
            }).then(() => {
                setStatus(false);
            });
        } else {
            apiCall({
                url: `/api/admin/${itemType}/`,
                method: 'POST',
                body: item,
            }).then(() => {
                setStatus(false);
            });
        }
    }

    if (status === null) {
        return <div>Loading...</div>;
    } else if (status === false) {
        return <Redirect to="/admin"></Redirect>;
    } else {
        return (
            <div className="edit-item">
                <h1>
                    {editMode ? 'Edit' : 'Add'} {itemType}
                </h1>
                <div className="buttons">
                    <button className="add" onClick={onSave}>
                        <SaveIcon /> Save
                    </button>
                    {editMode && (
                        <button className="danger-button" onClick={onDelete}>
                            <DeleteIcon /> Delete
                        </button>
                    )}
                </div>

                <ItemForm itemData={item} onItemUpdate={setItem} />
            </div>
        );
    }
}
