import './App.css';
import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Switch, Link, Redirect } from 'react-router-dom';
import Login from './pages/Login/Login';
import Portal from './pages/Portal/Portal';
import NotFound from './pages/NotFound/NotFound';
import Users from './pages/Admin/User/Users';
import EditUser from './pages/Admin/User/EditUser';
import Admin from './pages/Admin/Admin';
import Groups from './pages/Admin/Groups/Groups';
import EditGroup from './pages/Admin/Groups/EditGroup';
import Apps from './pages/Admin/Apps/Apps';
import EditApp from './pages/Admin/Apps/EditApp';
import { MdPowerSettingsNew as LogoutIcon, MdSettings as AdminIcon } from 'react-icons/md';

import apiCall from './services/api';

function App() {
    useEffect(() => {
        apiCall({
            url: '/api/userdata',
            method: 'GET',
        }).then(
            result => {
                if (result.success) {
                    setUserData(result.data);
                    setStatus(true);
                } else setStatus(false);
            },
            // Remarque : il est important de traiter les erreurs ici
            // au lieu d'utiliser un bloc catch(), pour ne pas passer à la trappe
            // des exceptions provenant de réels bugs du composant.
            error => {
                setStatus(false);
                console.error(error);
            }
        );
    }, []);

    const [status, setStatus] = useState(null);
    const [userData, setUserData] = useState({
        username: '',
        isadmin: false,
    });

    function disconnect() {
        window.location.href = '/api/logout';
    }

    function renderPrivatePage(props, Page) {
        return userData.username ? <Page {...props} /> : <Redirect to="/login" />;
    }

    function renderLoginPage(props, Page) {
        return !userData.username ? (
            <Page setUserData={setUserData} {...props} />
        ) : (
            <Redirect to="/" />
        );
    }

    if (status === null) {
        return <>Loading...</>;
    } else {
        return (
            <Router>
                <Link className="mainLogo" to="/">
                    <img alt="app logo " src="/img/logo.png" />
                </Link>
                <div className="top-section">
                    {userData.username && <b>{userData.username}</b>}
                    {userData.isadmin && (
                        <Link to="/admin">
                            <button>
                                <AdminIcon />
                                Administration
                            </button>
                        </Link>
                    )}
                    {userData.username && (
                        <button onClick={disconnect}>
                            <LogoutIcon />
                            Logout
                        </button>
                    )}
                </div>
                <div className="page-content">
                    <Switch>
                        <Route
                            exact
                            path="/login"
                            render={props => renderLoginPage(props, Login)}
                        />
                        <Route exact path="/" render={props => renderPrivatePage(props, Portal)} />
                        <Route
                            exact
                            path="/admin/"
                            render={props => renderPrivatePage(props, Admin)}
                        />
                        <Route
                            exact
                            path="/admin/users"
                            render={props => renderPrivatePage(props, Users)}
                        />
                        <Route
                            exact
                            path="/admin/users/edit"
                            render={props => renderPrivatePage(props, EditUser)}
                        />
                        <Route
                            exact
                            path="/admin/groups/"
                            render={props => renderPrivatePage(props, Groups)}
                        />
                        <Route
                            exact
                            path="/admin/groups/edit"
                            render={props => renderPrivatePage(props, EditGroup)}
                        />
                        <Route
                            exact
                            path="/admin/apps/"
                            render={props => renderPrivatePage(props, Apps)}
                        />
                        <Route
                            exact
                            path="/admin/apps/edit"
                            render={props => renderPrivatePage(props, EditApp)}
                        />
                        <NotFound></NotFound>
                    </Switch>
                </div>
            </Router>
        );
    }
}

export default App;
