import './Admin.css';
import React from 'react';
import { Link } from 'react-router-dom';

function Admin() {
    return (
        <div className="admin-menu">
            <Link className="menu-item" to="/admin/settings">
                General Settings
            </Link>
            <Link className="menu-item" to="/admin/users">
                Users
            </Link>
            <Link className="menu-item" to="/admin/groups">
                Groups
            </Link>
            <Link className="menu-item" to="/admin/apps">
                Apps
            </Link>
        </div>
    );
}

export default Admin;
