import './Apps.css';
import React, { useEffect, useState } from 'react';
import apiCall from '../../../services/api';
import { Redirect, Link } from 'react-router-dom';
import { MdAdd as AddIcon } from 'react-icons/md';
import ExploreList from '../../../components/ExploreList';

function Apps() {
    const [status, setStatus] = useState(null);
    const [apps, setApps] = useState([]);

    let appsListData = apps.map(app => {
        return { label: app.title, link: '/admin/apps/edit?id=' + app.id };
    });

    useEffect(() => {
        apiCall({
            url: '/api/admin/apps',
            method: 'GET',
        }).then(
            result => {
                if (result.success) {
                    setApps(result.data);
                    setStatus(true);
                } else setStatus(false);
            },
            error => {
                setStatus(false);
                console.error(error);
            }
        );
    }, []);

    if (status === null) {
        return <>Chargement...</>;
    } else if (status === false) {
        return <Redirect to="/"></Redirect>;
    } else
        return (
            <>
                <Link className="add-button" to="/admin/apps/edit">
                    <button>
                        <AddIcon /> Add app
                    </button>
                </Link>
                <ExploreList items={appsListData} />
            </>
        );
}

export default Apps;
