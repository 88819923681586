import React from 'react';

interface SelectProps {
    options: { key: number; value: string }[];
    value: number;
    onChange: any;
}

function Select(props: SelectProps) {
    function renderOptions() {
        return props.options.map(option => {
            return (
                <option key={option.key} value={option.key}>
                    {option.value}
                </option>
            );
        });
    }
    return (
        <select disabled={props.options.length === 0} value={props.value} onChange={props.onChange}>
            {renderOptions()}
        </select>
    );
}

export default Select;
