import React from 'react';
import { App } from '../../../../../types/types';
import { EditItem } from '../../../components/EditItem';

function EditApp() {
    return <EditItem itemType="apps" itemForm={EditAppForm} />;
}

function EditAppForm(props) {
    const appData: App = props.itemData;
    const setAppData = props.onItemUpdate;

    function changeAppName(event) {
        setAppData({ ...appData, title: event.target.value });
    }

    function changeAppImage(event) {
        setAppData({ ...appData, logo: event.target.value });
    }

    function changeAppLink(event) {
        setAppData({ ...appData, link: event.target.value });
    }

    function changeAppSubdomain(event) {
        setAppData({ ...appData, subdomain: event.target.value });
    }
    return (
        <div className="flex-grid-300">
            <div className="flex-col">
                <div className="input-label">Name</div>
                <input type="text" name="title" value={appData.title} onChange={changeAppName} />
                <div className="input-label">Image</div>
                <input type="text" name="logo" value={appData.logo} onChange={changeAppImage} />
            </div>
            <div className="flex-col">
                <div className="input-label">Link</div>
                <input type="text" name="link" value={appData.link} onChange={changeAppLink} />
                <div className="input-label">Subdomain</div>
                <input
                    type="text"
                    name="subdomain"
                    value={appData.subdomain}
                    onChange={changeAppSubdomain}
                />
            </div>
        </div>
    );
}

export default EditApp;
