import './Portal.css';
import React, { useEffect, useState } from 'react';
import * as MaterialDesign from 'react-icons/md';
import apiCall from '../../services/api';

function Portal(props) {
    const [status, setStatus] = useState(false);
    const [apps, setApps] = useState([]);

    useEffect(() => {
        apiCall({
            url: '/api/portaldata',
            method: 'GET',
        }).then(
            result => {
                if (result.success) {
                    setApps(result.data);
                    setStatus(true);
                } else setStatus(false);
            },
            error => {
                setStatus(false);
                console.error(error);
            }
        );
    }, []);

    function renderApps() {
        if (apps.length === 0) {
            return 'No application found.';
        }
        return apps.map(app => {
            let DisplayLogo = <></>;
            if (app.icon) {
                DisplayLogo = MaterialDesign[app.icon];
            } else if (app.logo) {
                DisplayLogo = React.createElement('img', {
                    size: 50,
                    src: `/img/apps/${app.logo}`,
                    className: 'app-logo-image',
                    alt: 'app logo',
                });
            }

            if (app.proxy) {
                return (
                    <div
                        key={app.id}
                        onClick={() => {
                            window.location.href = `/api/${app.id}/#`;
                        }}
                        className="app selectable"
                    >
                        <div className="app-logo-wrapper">{DisplayLogo}</div>

                        {app.title}
                    </div>
                );
            } else if (app.link) {
                return (
                    <div
                        key={app.id}
                        onClick={() => {
                            window.open(app.link, '_blank');
                        }}
                        className="app selectable"
                    >
                        <div className="app-logo-wrapper">{DisplayLogo}</div>
                        {app.title}
                    </div>
                );
            } else
                return (
                    <div key={app.id} className="app selectable disabled">
                        {DisplayLogo}
                        {app.title}
                    </div>
                );
        });
    }

    if (status === null) {
        return <>Loading...</>;
    } else {
        return <div className="apps">{renderApps()}</div>;
    }
}

export default Portal;
